var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        "mask-closable": false,
        closable: true,
        title: (_vm.mode == "add" ? "添加" : "编辑") + "提醒",
        "ok-text": "保存",
        size: "large",
        "z-index": 2,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        { staticClass: "v-ruleForm" },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "提醒名称", prop: "messageName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入1-20个字符" },
                    model: {
                      value: _vm.form.messageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "messageName", $$v)
                      },
                      expression: "form.messageName",
                    },
                  }),
                ],
                1
              ),
              _vm.customFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "日期设置", prop: "setDay" } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-select", {
                            staticClass: "width-bai",
                            attrs: {
                              placeholder: "请选择",
                              "allow-clear": "",
                              options: _vm.yeatOptions,
                            },
                            model: {
                              value: _vm.setYear,
                              callback: function ($$v) {
                                _vm.setYear = $$v
                              },
                              expression: "setYear",
                            },
                          }),
                          _vm._v("年 "),
                          _c("a-select", {
                            staticClass: "width-bai",
                            attrs: {
                              placeholder: "请选择",
                              "allow-clear": "",
                              options: _vm.monthOptions,
                            },
                            model: {
                              value: _vm.setMonth,
                              callback: function ($$v) {
                                _vm.setMonth = $$v
                              },
                              expression: "setMonth",
                            },
                          }),
                          _vm._v("月 "),
                          _c("a-select", {
                            staticClass: "width-bai",
                            attrs: {
                              placeholder: "请选择",
                              "allow-clear": "",
                              options: _vm.dayOptions,
                            },
                            on: {
                              change: function () {
                                this$1.$forceUpdate()
                                _vm.$refs.ruleForm.validateField("setDay")
                              },
                            },
                            model: {
                              value: _vm.form.setDay,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "setDay", $$v)
                              },
                              expression: "form.setDay",
                            },
                          }),
                          _vm._v("日 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.form.remindTimeList, function (item, index) {
                return _c(
                  "div",
                  { key: "remindTimeLis" + index, staticClass: "flex-display" },
                  [
                    _c("div", { staticClass: "v-lable-all" }, [
                      index == 0
                        ? _c("div", { staticClass: "v-lable" }, [
                            _c("span", { staticClass: "v-lable-span" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", [_vm._v("提醒时间")]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "a-space",
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "remindTimeList." + index + ".aroundType",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("dictionariesInput", {
                              staticClass: "width-bai",
                              attrs: { parameter: "around_type" },
                              on: {
                                getDictionariesValue: _vm.getDictionariesValue,
                              },
                              model: {
                                value: item.aroundType,
                                callback: function ($$v) {
                                  _vm.$set(item, "aroundType", $$v)
                                },
                                expression: "item.aroundType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "remindTimeList." + index + ".aroundNum",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("a-input-number", {
                              attrs: { min: 1 },
                              model: {
                                value: item.aroundNum,
                                callback: function ($$v) {
                                  _vm.$set(item, "aroundNum", $$v)
                                },
                                expression: "item.aroundNum",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "remindTimeList." + index + ".aroundUnit",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("dictionariesInput", {
                              staticClass: "width-bai",
                              attrs: { parameter: "around_unit" },
                              model: {
                                value: item.aroundUnit,
                                callback: function ($$v) {
                                  _vm.$set(item, "aroundUnit", $$v)
                                },
                                expression: "item.aroundUnit",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "remindTimeList." + index + ".remindTime",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("a-time-picker", {
                              attrs: {
                                format: "HH:mm",
                                "value-format": "HH:mm",
                              },
                              model: {
                                value: item.remindTime,
                                callback: function ($$v) {
                                  _vm.$set(item, "remindTime", $$v)
                                },
                                expression: "item.remindTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "remindTimeList." + index + ".dayType",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("dictionariesInput", {
                              staticClass: "width-bai",
                              attrs: { parameter: "day_type" },
                              on: {
                                change: function ($event) {
                                  return _vm.chengev(index)
                                },
                              },
                              model: {
                                value: item.dayType,
                                callback: function ($$v) {
                                  _vm.$set(item, "dayType", $$v)
                                },
                                expression: "item.dayType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          [
                            index != 0
                              ? _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemoveTime(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 移除 ")]
                                )
                              : _vm._e(),
                            index == 0
                              ? _c(
                                  "a-button",
                                  { on: { click: _vm.handleAddTime } },
                                  [_vm._v(" 添加 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.form.applyScopeList, function (item, index) {
                return _c(
                  "div",
                  { key: "applyScope" + index, staticClass: "flex-display" },
                  [
                    _c("div", { staticClass: "v-lable-all" }, [
                      index == 0
                        ? _c("div", { staticClass: "v-lable" }, [
                            _c("span", { staticClass: "v-lable-span" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", [_vm._v("适用范围")]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "a-space",
                      { staticClass: "flex-display" },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "applyScopeList." + index + ".scopeType",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("dictionariesInput", {
                              staticClass: "width-dbbai",
                              attrs: { parameter: "scope_type" },
                              model: {
                                value: item.scopeType,
                                callback: function ($$v) {
                                  _vm.$set(item, "scopeType", $$v)
                                },
                                expression: "item.scopeType",
                              },
                            }),
                          ],
                          1
                        ),
                        item.scopeType == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "applyScopeList." +
                                        index +
                                        ".scopeContent",
                                      rules: {
                                        required: true,
                                        message: "请选择",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("depTreeSelect", {
                                      staticClass: "width-dbbai",
                                      attrs: { placeholder: "请选择部门" },
                                      model: {
                                        value: item.scopeContent,
                                        callback: function ($$v) {
                                          _vm.$set(item, "scopeContent", $$v)
                                        },
                                        expression: "item.scopeContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.scopeType == 3
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "applyScopeList." +
                                        index +
                                        ".scopeContent",
                                      rules: {
                                        required: true,
                                        message: "请选择",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("dictionariesInput", {
                                      staticClass: "width-dbbai",
                                      attrs: { parameter: "recruit_type" },
                                      model: {
                                        value: item.scopeContent,
                                        callback: function ($$v) {
                                          _vm.$set(item, "scopeContent", $$v)
                                        },
                                        expression: "item.scopeContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.scopeType == 4
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "applyScopeList." +
                                        index +
                                        ".scopeContent",
                                      rules: {
                                        required: true,
                                        message: "请选择",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("rankInput", {
                                      staticClass: "width-dbbai",
                                      model: {
                                        value: item.scopeContent,
                                        callback: function ($$v) {
                                          _vm.$set(item, "scopeContent", $$v)
                                        },
                                        expression: "item.scopeContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.scopeType == 5
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "applyScopeList." +
                                        index +
                                        ".scopeContent",
                                      rules: {
                                        required: true,
                                        message: "请选择",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    item.scopeType == 5
                                      ? _c("postInput", {
                                          staticClass: "width-dbbai",
                                          model: {
                                            value: item.scopeContent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "scopeContent",
                                                $$v
                                              )
                                            },
                                            expression: "item.scopeContent",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-form-model-item",
                          [
                            index != 0
                              ? _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemoveApply(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 移除 ")]
                                )
                              : _vm._e(),
                            index == 0
                              ? _c(
                                  "a-button",
                                  { on: { click: _vm.handleAddApply } },
                                  [_vm._v(" 添加 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.form.notifierList, function (item, index) {
                return _c(
                  "div",
                  { key: "notifierList" + index, staticClass: "flex-display" },
                  [
                    _c("div", { staticClass: "v-lable-all" }, [
                      index == 0
                        ? _c("div", { staticClass: "v-lable" }, [
                            _c("span", { staticClass: "v-lable-span" }, [
                              _vm._v("*"),
                            ]),
                            _c("span", { staticClass: "person" }, [
                              _vm._v("通知人"),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "a-space",
                      { staticClass: "flex-display" },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              prop: "notifierList." + index + ".notifierType",
                              rules: {
                                required: true,
                                message: "请选择",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c("dictionariesInput", {
                              staticClass: "width-dbbai",
                              attrs: { parameter: "notifier_type" },
                              model: {
                                value: item.notifierType,
                                callback: function ($$v) {
                                  _vm.$set(item, "notifierType", $$v)
                                },
                                expression: "item.notifierType",
                              },
                            }),
                          ],
                          1
                        ),
                        item.notifierType == 3
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "notifierList." + index + ".onJobIds",
                                      rules: {
                                        required: true,
                                        message: "请选择指定通知人",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("perTreeSelect", {
                                      staticStyle: { width: "200px" },
                                      model: {
                                        value: item.onJobIds,
                                        callback: function ($$v) {
                                          _vm.$set(item, "onJobIds", $$v)
                                        },
                                        expression: "item.onJobIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.notifierType == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      prop:
                                        "notifierList." + index + ".onJobIds",
                                      rules: {
                                        required: true,
                                        message: "请选择指定通知人",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        model: {
                                          value: item.onJobIds,
                                          callback: function ($$v) {
                                            _vm.$set(item, "onJobIds", $$v)
                                          },
                                          expression: "item.onJobIds",
                                        },
                                      },
                                      _vm._l(_vm.userList, function (itemm) {
                                        return _c(
                                          "a-select-option",
                                          { key: itemm.id },
                                          [
                                            _vm._v(
                                              " " + _vm._s(itemm.name) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-form-model-item",
                          [
                            index != 0
                              ? _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemoveNotifier(index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 移除 ")]
                                )
                              : _vm._e(),
                            index == 0
                              ? _c(
                                  "a-button",
                                  { on: { click: _vm.handleAddNotifier } },
                                  [_vm._v(" 添加 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "a-form-model-item",
                { attrs: { label: "提醒方式", prop: "noteTyps" } },
                [
                  _c("a-checkbox-group", {
                    attrs: { options: _vm.plainOptions },
                    on: { change: _vm.changeNoteType },
                    model: {
                      value: _vm.form.noteTyps,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "noteTyps", $$v)
                      },
                      expression: "form.noteTyps",
                    },
                  }),
                ],
                1
              ),
              _vm.form.noteFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "短信模板", prop: "noteTempId" } },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.form.noteTempId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "noteTempId", $$v)
                            },
                            expression: "form.noteTempId",
                          },
                        },
                        _vm._l(_vm.modalType, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.emailFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "发送邮件", prop: "emailId" } },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.form.emailId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "emailId", $$v)
                            },
                            expression: "form.emailId",
                          },
                        },
                        _vm._l(_vm.emailList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.emailId,
                              attrs: { value: item.emailId },
                            },
                            [_vm._v(" " + _vm._s(item.sender) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.emailFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "邮件主题", prop: "emailTitle" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入1-20个字符" },
                        model: {
                          value: _vm.form.emailTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "emailTitle", $$v)
                          },
                          expression: "form.emailTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.emailFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "上传附件" } },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            name: "file",
                            multiple: true,
                            action: "",
                            "custom-request": _vm.custonRequest,
                            headers: _vm.headers,
                            "show-upload-list": true,
                            "file-list": _vm.fileList,
                            remove: _vm.removeFile,
                          },
                        },
                        [
                          _c(
                            "a-button",
                            [
                              _c("a-icon", { attrs: { type: "upload" } }),
                              _vm._v(" 上传文件 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.emailFlag == 1
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "详情" } },
                    [
                      _c("Tinymce-vue", {
                        attrs: { value: _vm.content, setting: _vm.setting },
                        on: {
                          input: function (res) {
                            return (_vm.content = res)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }